import React from "react";
import { Link } from "react-router-dom";
import GameCard from "./GameCard";
import Games from "../json/Game.json";
import First from "../Ads/First";

export default function Home() {
  return (
    <>
      <div className="md:px-10 p-3">
        <Link
          to={"/"}
          className="capitalize text-blue-500 font-bold my-3 md:text-4xl text-2xl"
        >
          diamond <span className="text-yellow-400">mining</span>
        </Link>

        <p className="my-4">
          Let me know if you need more suggestions or if there's a particular
          theme or style you're aiming
        </p>

        <div className="relative rounded-xl overflow-auto p-1 capitalize">
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4 font-mono  text-sm text-center font-bold leading-6 bg-stripes-fuchsia rounded-lg">
            <Link
              to={"/surveys"}
              className="p-4 rounded-lg shadow-lg bg-[#FDBA01]"
            >
              Surveys
            </Link>
            <Link
              to={"/photo-wall"}
              className="p-4 rounded-lg shadow-lg bg-[#FDBA01]"
            >
              Photo Walls
            </Link>
            <Link
              to={"/quiz"}
              className="p-4 rounded-lg shadow-lg bg-[#FDBA01]"
            >
              Quiz
            </Link>
          </div>
        </div>

        <div className="my-2 h-64">
          <First />
        </div>

        <p className="text-white my-2">Daily View</p>

        <div className="relative rounded-xl overflow-auto p-1 capitalize my-2">
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4 font-mono  text-sm text-center font-bold leading-6 bg-stripes-fuchsia rounded-lg">
            <Link to={"/cpw"} className="p-4 rounded-lg shadow-lg bg-[#FDBA01]">
              character
            </Link>
            <Link to={"/cpw"} className="p-4 rounded-lg shadow-lg bg-[#FDBA01]">
              Pet
            </Link>
            <Link to={"/cpw"} className="p-4 rounded-lg shadow-lg bg-[#FDBA01]">
              Weapons
            </Link>
          </div>
        </div>

        <p className="text-white my-2">Game Zone</p>

        <div className="relative rounded-xl overflow-auto p-1 capitalize my-2">
          <div className="grid grid-cols-3 md:grid-cols-6 md:gap-6 gap-3 font-mono text-sm text-center font-bold leading-6 bg-stripes-fuchsia rounded-lg">
            {Games.map((_data, index) => (
              <GameCard key={index} data={_data} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
