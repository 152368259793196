import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import QuizData from "../json/Quiz.json";
import First from "../Ads/First";
import Second from "../Ads/Second";
import Third from "../Ads/Third";

export default function Quiz() {
  const [startServe, setStartServe] = useState(true);
  const [showAdScreen, setShowAdScreen] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(null);
  const [answeredQuestions, setAnsweredQuestions] = useState(0);
  const [showThankYou, setShowThankYou] = useState(false);
  const [questions, setQuestions] = useState([...QuizData]); // Copy of the original data

  const [isLoading, setIsLoading] = useState(false);
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    if (isLoading) {
      const timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev === 1) {
            clearInterval(timer);
            setIsLoading(false);
          }
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [isLoading]);

  const startQuiz = () => {
    setStartServe(false);
    setShowAdScreen(true);
  };

  const showQuizQuestions = () => {
    setShowAdScreen(false);
    getRandomQuestion();
  };

  const getRandomQuestion = () => {
    if (questions.length > 0) {
      const randomIndex = Math.floor(Math.random() * questions.length);
      setCurrentQuestionIndex(randomIndex);
      setIsLoading(true);
      setCountdown(5);
    } else {
      setShowThankYou(true);
    }
  };

  const handleAnswer = () => {
    setAnsweredQuestions(answeredQuestions + 1);

    if (answeredQuestions < 2) {
      const remainingQuestions = [...questions];
      remainingQuestions.splice(currentQuestionIndex, 1); // Remove the answered question
      setQuestions(remainingQuestions);
      getRandomQuestion();
    } else {
      setShowThankYou(true);
    }
  };

  const restartQuiz = () => {
    setAnsweredQuestions(0);
    setShowThankYou(false);
    setStartServe(true);
    setShowAdScreen(false);
    setQuestions([...QuizData]); // Reset the questions to the original set
    setCurrentQuestionIndex(null); // Reset the current question index
  };

  return (
    <div className="md:px-10 p-3">
      <Link to={"/quiz"} className="capitalize font-bold">
        {showAdScreen ? "Quiz on Eat Right Quest" : "Sports Quiz"}
      </Link>
      {startServe ? (
        <>
          <div className="my-6 h-60">
            <First />
          </div>
          <div className="flex justify-center my-6">
            <img
              src="https://media.istockphoto.com/id/1646443939/photo/sunny-cricket-moments.jpg?s=1024x1024&w=is&k=20&c=NEk5TsN2oPb_ipVmIiyPIPtbAqXFcg0UyHmiEzw2Zz4="
              alt="images"
            />
          </div>
          <button
            type="button"
            className="text-black w-full my-8 bg-[#FDBA01] hover:bg-yellow-500 focus:outline-none focus:ring-4 focus:ring-yellow-300 font-medium text-sm px-5 py-2.5 text-center me-2 mb-2 dark:focus:ring-yellow-900 rounded-lg"
            onClick={startQuiz}
          >
            Let’s Go
          </button>
          <p className="text-center my-6">
            Sports quizzes are fun and educational events that can help people
            learn more about sports and their history. They can also help people
            test their sports knowledge and trivia skills. Here are some things
            to know about sports quizzes:
          </p>
        </>
      ) : showAdScreen ? (
        <div className="my-6">
          <ul className="">
            <li className="py-3">
              <span className="bg-white text-black px-1.5 p-1 me-5 rounded-3xl">
                10
              </span>
              Questions
            </li>
            <li className="py-3">
              <span className="bg-white text-black px-1.5 p-1 me-5 rounded-3xl">
                10
              </span>
              Durations(Sec)
            </li>
            <li className="py-3">
              <span className="bg-white text-black px-1.5 p-1 me-5 rounded-3xl">
                20
              </span>
              Diamond
            </li>
            <li className="py-3">
              <span className="bg-white text-black px-2 p-1 me-6 rounded-3xl">
                {" "}
                ?{" "}
              </span>
              -
            </li>
          </ul>
          <div className="my-6 h-60 text-center">
            <Second />
          </div>
          <button
            type="button"
            className="text-black w-full my-8 bg-[#FDBA01] hover:bg-yellow-500 focus:outline-none focus:ring-4 focus:ring-yellow-300 font-medium text-sm px-5 py-2.5 text-center me-2 mb-2 dark:focus:ring-yellow-900 rounded-lg"
            onClick={showQuizQuestions}
          >
            Play Now
          </button>
        </div>
      ) : showThankYou ? (
        <div className="my-6">
          <div className="my-6 h-60 text-center">
            <Third />
          </div>
          <h3 className="font-bold mb-4 capitalize text-4xl text-center">
            Thanks for Playing
          </h3>

          <div className="flex-col gap-4 w-full flex items-center justify-center my-6">
            <div className="w-28 h-28 border-8 text-blue-400 text-4xl animate-spin border-gray-300 flex items-center justify-center border-t-blue-400 rounded-full"></div>
          </div>

          <div className="relative rounded-xl overflow-auto p-1 capitalize text-black">
            <div className="grid grid-cols-2 gap-4 font-mono text-sm text-center font-bold leading-6 bg-stripes-fuchsia rounded-lg">
              <button
                className="p-4 rounded-lg shadow-lg bg-white hover:bg-yellow-500 capitalize"
                onClick={restartQuiz}
              >
                More
              </button>
              <Link
                to="/"
                className="p-4 rounded-lg shadow-lg bg-white hover:bg-yellow-500 capitalize"
              >
                Games
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className="my-6">
          {currentQuestionIndex !== null && (
            <>
              <h3 className="font-bold text-lg mb-4">
                {answeredQuestions + 1}.{" "}
                {questions[currentQuestionIndex].question}
              </h3>

              <div className="my-6 h-60 ">
                <First />
              </div>

              <div className="flex-col gap-4 w-full flex items-center justify-center my-6">
                <div className="w-28 h-28 border-8 text-blue-400 text-4xl animate-spin border-gray-300 flex items-center justify-center border-t-blue-400 rounded-full">
                  {countdown}
                </div>
              </div>

              <div className="relative rounded-xl overflow-auto p-1 capitalize text-black my-10">
                <div className="grid grid-cols-2 gap-4 font-mono text-sm text-center font-bold leading-6 bg-stripes-fuchsia rounded-lg">
                  <button
                    className="p-4 rounded-lg shadow-lg bg-white hover:bg-yellow-500 capitalize"
                    onClick={handleAnswer}
                    disabled={countdown !== 0}
                  >
                    No
                  </button>
                  <button
                    className="p-4 rounded-lg shadow-lg bg-white hover:bg-yellow-500 capitalize"
                    onClick={handleAnswer}
                    disabled={countdown !== 0}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}
