import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./Component/Home";
import Surveys from "./Component/Surveys";
import PhotoWall from "./Component/PhotoWall";
import Quiz from "./Component/Quiz";
import CPW from "./Component/CPW";

function App() {
  return (
    <BrowserRouter>
      <div className="overflow-auto container bg-black text-white">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/surveys" element={<Surveys />} />
          <Route path="/photo-wall" element={<PhotoWall />} />
          <Route path="/quiz" element={<Quiz />} />
          <Route path="/cpw" element={<CPW />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
