import React from "react";

export default function GameCard({ data }) {
  return (
    <div className="relative group duration-500 cursor-pointer group overflow-hidden text-gray-50 h-20 w-full rounded-xl hover:duration-700">
      <img src={data?.logo} alt={data?.title} className="w-full h-40" />
      <div className="absolute bg-gray-50 bottom-0 w-full p-1 flex flex-col gap-1">
        <span className="text-lime-900 font-bold text-xs">{data?.title}</span>
      </div>
    </div>
  );
}
