/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import CPWData from "../json/CPW.json";
import First from "../Ads/First";
import Second from "../Ads/Second";
import Third from "../Ads/Third";

export default function CPW() {
  const [startServe, setStartServe] = useState(true);
  const [currentImageIndex, setCurrentImageIndex] = useState(null);
  const [viewedImages, setViewedImages] = useState([]);
  const [showThankYou, setShowThankYou] = useState(false);
  const [showAdScreen, setShowAdScreen] = useState(false);

  const startPhotoWalls = () => {
    setShowAdScreen(true);
    setStartServe(false); // This hides the initial content and shows the first image
  };

  const showQuizQuestions = () => {
    setShowAdScreen(false);
    showRandomImage();
  };

  const showRandomImage = () => {
    if (viewedImages.length < 3) {
      const randomIndex = Math.floor(Math.random() * CPWData.length);
      setCurrentImageIndex(randomIndex);
      setViewedImages([...viewedImages, CPWData[randomIndex]]);
    } else {
      setShowThankYou(true);
    }
  };

  const handleNext = () => {
    showRandomImage();
  };

  const restartPhotoWalls = () => {
    setViewedImages([]);
    setShowThankYou(false);
    setShowAdScreen(false);
    setStartServe(true); // Resets to show the initial screen again
    setCurrentImageIndex(null);
  };

  return (
    <div className="md:px-10 p-3">
      <Link
        to={"/cpw"}
        className="capitalize font-bold"
        onClick={restartPhotoWalls}
      >
        Charecter Name
      </Link>
      {startServe ? (
        <>
          <div className="flex justify-center my-6">
            <img src={CPWData[0]} alt="Preview Image" className="h-48 w-80" />
          </div>
          <div className="my-6 h-64">
            <First />
          </div>
          <p className="text-center my-6">
            To view the latest photos from Photo Walls. We hope you enjoy the
            snapshots and find them as memorable as we do.
          </p>
          <button
            type="button"
            className="text-black w-full my-8 bg-[#FDBA01] hover:bg-yellow-500 focus:outline-none focus:ring-4 focus:ring-yellow-300 font-medium text-sm px-5 py-2.5 text-center me-2 mb-2 dark:focus:ring-yellow-900 rounded-lg"
            onClick={startPhotoWalls}
          >
            Reveal
          </button>
        </>
      ) : showAdScreen ? (
        <div className="my-6">
          <div className="flex justify-center my-6">
            <img src={CPWData[1]} alt="Preview Image" className="h-96 w-80" />
          </div>
          <div className="my-6 h-64 text-center">
            <Second />
          </div>
          <p>Profession : Doctor Maniac</p>
          <button
            type="button"
            className="text-black w-full my-8 bg-[#FDBA01] hover:bg-yellow-500 focus:outline-none focus:ring-4 focus:ring-yellow-300 font-medium text-sm px-5 py-2.5 text-center me-2 mb-2 dark:focus:ring-yellow-900 rounded-lg"
            onClick={showQuizQuestions}
          >
            Next
          </button>
        </div>
      ) : showThankYou ? (
        <div className="my-6">
          <div className="my-6 h-64 text-center">
            <Third />
          </div>
          <h3 className="font-bold mb-4 capitalize text-4xl text-center">
            Thanks for Watching
          </h3>
          <p className="my-6 text-center">
            Thank you for taking the time to view our photo wall! We hope you
            enjoyed it.
          </p>

          <div className="flex-col gap-4 w-full flex items-center justify-center my-6">
            <div className="w-28 h-28 border-8 text-blue-400 text-4xl animate-spin border-gray-300 flex items-center justify-center border-t-blue-400 rounded-full"></div>
          </div>

          <div className="relative rounded-xl overflow-auto p-1 capitalize text-black mt-6">
            <div className="grid grid-cols-2 gap-4 font-mono text-sm text-center font-bold leading-6 bg-stripes-fuchsia rounded-lg">
              <button
                className="p-4 rounded-lg shadow-lg bg-white hover:bg-yellow-500 capitalize"
                onClick={restartPhotoWalls}
              >
                More
              </button>
              <Link
                to="/"
                className="p-4 rounded-lg shadow-lg bg-white hover:bg-yellow-500 capitalize"
              >
                Games
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className="my-6">
          {currentImageIndex !== null && (
            <>
              <div className="flex justify-center my-6">
                <img
                  src={CPWData[currentImageIndex]}
                  alt="Random Image"
                  className="h-48 w-80 rounded-lg"
                />
              </div>
              <div className="my-6 h-64 ">
                <First />
              </div>
              <button
                type="button"
                className="text-black w-full my-8 bg-[#FDBA01] hover:bg-yellow-500 focus:outline-none focus:ring-4 focus:ring-yellow-300 font-medium text-sm px-5 py-2.5 text-center me-2 mb-2 dark:focus:ring-yellow-900 rounded-lg"
                onClick={handleNext}
              >
                Next
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
}
