import React, { useEffect, useState } from "react";
import ic_mistrybox1 from "../ic_mistrybox1.png";
import { Link } from "react-router-dom";
import SurveysData from "../json/Surveys.json";
import First from "../Ads/First";
import Second from "../Ads/Second";
import Third from "../Ads/Third";

export default function Surveys() {
  const [startServe, setStartServe] = useState(true);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(null);
  const [answeredQuestions, setAnsweredQuestions] = useState(0);
  const [showThankYou, setShowThankYou] = useState(false);
  const [questions, setQuestions] = useState([...SurveysData]); // Copy of the original data

  const [isLoading, setIsLoading] = useState(false);
  const [countdown, setCountdown] = useState(5);

  useEffect(() => {
    if (isLoading) {
      const timer = setInterval(() => {
        setCountdown((prev) => {
          if (prev === 1) {
            clearInterval(timer);
            setIsLoading(false);
          }
          return prev - 1;
        });
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [isLoading]);

  const startSurvey = () => {
    getRandomQuestion();
    setStartServe(false);
  };

  const getRandomQuestion = () => {
    if (questions.length > 0) {
      const randomIndex = Math.floor(Math.random() * questions.length);
      setCurrentQuestionIndex(randomIndex);
      setIsLoading(true);
      setCountdown(5);
    } else {
      setShowThankYou(true);
    }
  };

  const handleAnswer = () => {
    setAnsweredQuestions(answeredQuestions + 1);

    if (answeredQuestions < 2) {
      const remainingQuestions = [...questions];
      remainingQuestions.splice(currentQuestionIndex, 1); // Remove the answered question
      setQuestions(remainingQuestions);
      getRandomQuestion();
    } else {
      setShowThankYou(true);
    }
  };

  const restartSurvey = () => {
    setAnsweredQuestions(0);
    setShowThankYou(false);
    setStartServe(true);
    setQuestions([...SurveysData]); // Reset the questions to the original set
    setCurrentQuestionIndex(null); // Reset the current question index
  };

  return (
    <div className="md:px-10 p-3">
      <Link to={"/surveys"} className="capitalize font-bold">
        Surveys
      </Link>
      {startServe ? (
        <>
          <div className="my-6 h-60 ">
            <First />
          </div>
          <div className="flex justify-center my-6">
            <img src={ic_mistrybox1} alt="images" />
          </div>
          <p className="text-center my-6">
            Surveys are a popular method for collecting data from people. They
            can be used to gather opinions, understand behaviors, or collect
            demographic information. Surveys can be conducted in various
            formats, including online questionnaires, phone interviews,
            face-to-face interactions, or mailed forms.
          </p>
          <button
            type="button"
            className="text-black w-full my-8 bg-[#FDBA01] hover:bg-yellow-500 focus:outline-none focus:ring-4 focus:ring-yellow-300 font-medium text-sm px-5 py-2.5 text-center me-2 mb-2 dark:focus:ring-yellow-900 rounded-lg"
            onClick={startSurvey}
          >
            Start Now
          </button>
        </>
      ) : showThankYou ? (
        <div className="my-6">
          <div className="my-6 h-60 text-center">
            <Second />
          </div>
          <h3 className="font-bold mb-4 capitalize text-4xl text-center">
            Thank You😊
          </h3>
          <p className="my-6 text-center">
            for taking the time to complete our survey! Your feedback is
            incredibly important to us and will play a crucial role in helping
            us
          </p>
          <div className="relative rounded-xl overflow-auto p-1 capitalize text-black">
            <div className="grid grid-cols-2 gap-4 font-mono text-sm text-center font-bold leading-6 bg-stripes-fuchsia rounded-lg">
              <button
                className="p-4 rounded-lg shadow-lg bg-white hover:bg-yellow-500 capitalize"
                onClick={restartSurvey}
              >
                More
              </button>
              <Link
                to="/"
                className="p-4 rounded-lg shadow-lg bg-white hover:bg-yellow-500 capitalize"
              >
                Games
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className="my-6">
          {currentQuestionIndex !== null && (
            <>
              <h3 className="font-bold text-lg mb-4">
                {answeredQuestions + 1}.{" "}
                {questions[currentQuestionIndex].question}
              </h3>

              <div className="my-6 h-60">
                <Third />
              </div>

              <div className="flex-col gap-4 w-full flex items-center justify-center my-6">
                <div className="w-28 h-28 border-8 text-blue-400 text-4xl animate-spin border-gray-300 flex items-center justify-center border-t-blue-400 rounded-full">
                  {countdown}
                </div>
              </div>

              <div className="relative rounded-xl overflow-auto p-1 capitalize text-black my-10">
                <div className="grid grid-cols-2 gap-4 font-mono text-sm text-center font-bold leading-6 bg-stripes-fuchsia rounded-lg">
                  <button
                    className="p-4 rounded-lg shadow-lg bg-white hover:bg-yellow-500 capitalize"
                    onClick={handleAnswer}
                    disabled={countdown !== 0}
                  >
                    No
                  </button>
                  <button
                    className="p-4 rounded-lg shadow-lg bg-white hover:bg-yellow-500 capitalize"
                    onClick={handleAnswer}
                    disabled={countdown !== 0}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}
